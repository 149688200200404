<template>
  <b-row>
    <b-col lg="6" md="12">
      <div class="theme-font-regular font-16 pt-2" v-html="expandedVwTxt"></div>
    </b-col>
    <b-col lg="6" md="12">
      <vc-calendar
        ref="calendar"
        class="border-0 mywaste-calendar"
        :masks="{ weekdays: 'WWW' }"
        :attributes="calendarList"
        is-expanded
        @dayclick="calendarDayClicked"
      />
    </b-col>
  </b-row>
</template>
<script>
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'Calendar-two-column-grid',
  data() {
    return {
      isDayClicked: false,
      eventsList: this.content.eventsResults,
      selectedDate: moment(new Date()).format('MM-DD-YYYY'),
    }
  },
  props: {
    content: { type: Object },
    expandedVwTxt: { type: String },
  },
  methods: {
    async calendarDayClicked(date) {
      this.isDayClicked = true
      const eventResults = cloneDeep(this.content.eventsResults)
      const selectedDate = moment(date.id).format('MM-DD-YYYY')
      this.selectedDate = selectedDate
      let url
      const uriSet = new Set()

      const events = eventResults.filter((event) => {
        return moment(event.eventDate).format('MM-DD-YYYY') === this.selectedDate ? event : null
      })
      for (const event of events) {
        url = new URL(event.detailURL)
        const pathUri = url.pathname.split('/')[1]
        uriSet.add(pathUri)
      }
      const uriArr = Array.from(uriSet)
      const baseUrl = `${url.protocol}//${url.hostname}`
      const pathUri = uriArr[0]

      window.open(`${baseUrl}/${pathUri}`, '_blank')
    },
  },
  computed: {
    calendarList() {
      return [
        {
          key: 'today',
          highlight: {
            color: 'black',
            fillMode: 'outline',
            style: { borderWidth: '1px' },
          },
          dates: new Date(),
        },
        {
          key: 'event',
          highlight: {
            class: 'scheduled',
            contentClass: 'container-scheduled',
          },
          dates: this.content.eventsResults.map((event) => {
            return new Date(moment(event.eventDate).format('MM-DD-YYYY'))
          }),
        },
      ]
    },
  },
}
</script>
<style lang="scss" scoped>
.right-column {
  width: 40%;
}
.left-column {
  width: 55%;
}
</style>
